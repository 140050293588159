import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { palexy } from '../../../generated/bundle';
import { UtilsService } from '../../../common/utils';
import { QcService } from '../../qc.service';
import { DateService } from '../../../common/date';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from '../../../common/company.service';
import { StoreService } from '../../../common/store.service';


@Component({
  selector: 'create-qc-plan-dialog',
  templateUrl: './create-qc-plan-dialog.component.html',
  styleUrls: ['./create-qc-plan-dialog.component.css']
})
export class CreateQcPlanDialogComponent {

  companyControl = new FormControl();
  companyFilterControl = new FormControl();
  storeControl = new FormControl();
  startDateControl = new FormControl();
  endDateControl = new FormControl();

  filteredStores: BehaviorSubject<palexy.streaming.v1.IStore[]> = new BehaviorSubject<palexy.streaming.v1.IStore[]>([]);
  filteredCompanies: BehaviorSubject<palexy.streaming.v1.ICompany[]> = new BehaviorSubject<palexy.streaming.v1.ICompany[]>([]);

  selectedStores: palexy.streaming.v1.IStore[] = [];
  companyStores: palexy.streaming.v1.IStore[] = [];
  selectedCompany: palexy.streaming.v1.ICompany = null;

  stores: palexy.streaming.v1.IStore[];
  companies: palexy.streaming.v1.ICompany[];
  storeNameMap: Map<string, palexy.streaming.v1.IStore> = new Map();

  errorMessage: string = null;
  isSaving: boolean = false;

  private _onDestroy = new Subject<void>();

  @ViewChild('storeInput') storeInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    storeService: StoreService,
    private qcService: QcService,
    companyService: CompanyService,
    private dateService: DateService,
    private utilsService: UtilsService,
    private dialogRef: MatDialogRef<CreateQcPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    const fetchCompanies = companyService.getCompanies();
    const fetchStores = storeService.fetchStores();
    forkJoin([fetchCompanies, fetchStores]).subscribe(results => {
      this.companies = results[0].companies;
      this.stores = results[1].stores;

      for (const store of this.stores) {
        this.storeNameMap.set(store.displayName, store);
      }

      this.filteredCompanies.next(this.companies);
      this.companyFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.utilsService.filter(this.companies, this.filteredCompanies, this.companyFilterControl, c => c.name)
        });
      this.companyControl.valueChanges.pipe(
        takeUntil(this._onDestroy)
      ).subscribe(() => this.utilsService.filter(this.companies, this.filteredCompanies, this.companyFilterControl, c => c.name));

      this.filteredStores.next(this.stores);
      this.storeControl.valueChanges.pipe(
        takeUntil(this.companyControl.valueChanges)
      ).subscribe(() => this.utilsService.filter(this.stores, this.filteredStores, this.storeControl, s => s.displayName));
    })
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  selectCompany(company: palexy.streaming.v1.ICompany) {
    this.selectedCompany = company;
    this.companyStores = this.stores.filter(s => s.companyId == this.selectedCompany.id);
    this.filteredStores.next(this.companyStores);
    this.storeControl.valueChanges.pipe(
      takeUntil(this.companyControl.valueChanges)
    ).subscribe(() => this.utilsService.filter(this.companyStores, this.filteredStores, this.storeControl, s => s.displayName));
    this.storeInput.nativeElement.value = '';
    this.storeControl.setValue(null);
  }

  removeStore(store: palexy.streaming.v1.IStore): void {
    const index = this.selectedStores.indexOf(store);

    if (index >= 0) {
      this.selectedStores.splice(index, 1);
    }
  }

  selecteStore(event: MatAutocompleteSelectedEvent): void {
    const storeDisplayName = event.option.value;
    const store = this.storeNameMap.get(storeDisplayName);
    if (store) {
      this.selectedStores.push(store);
    }
    this.storeInput.nativeElement.value = '';
    this.storeControl.setValue(null);
  }

  showStoreOptions() {
      this.storeInput.nativeElement.blur();
      setTimeout(() => this.storeInput.nativeElement.focus(), 0);
  }

  craftQcPlanItem(store: palexy.streaming.v1.IStore, dateId: string): palexy.sherlock.v1.QcPlanItem {
    const item = palexy.sherlock.v1.QcPlanItem.create();
    item.dateId = dateId;
    item.storeId = store.id;
    item.companyId = store.companyId;
    item.isCustomItem = true;
    return item;
  }

  submit() {
    const planItems: palexy.sherlock.v1.QcPlanItem[] = [];
    const dateIds = this.dateService.generateDateIdList(this.startDateControl.value, this.endDateControl.value);
    const qcPlanItems = [];
    for (const store of this.selectedStores) {
      for (const dateId of dateIds) {
        qcPlanItems.push(this.craftQcPlanItem(store, dateId));
      }
    }

    const request = palexy.sherlock.v1.BatchCreateQcPlanItemsRequest.create();
    request.items = qcPlanItems;

    this.errorMessage = null;
    this.isSaving = true;
    this.qcService.batchCreateQcPlanItems(request).subscribe(
      response => {
        this.isSaving = false;
        this.dialogRef.close(response.items)
      },
      error => {
        this.isSaving = false;
        this.errorMessage = error?.error?.message ?? error.message;
      }
    )
  }

  cancel() {
    this.dialogRef.close();
  }
}


